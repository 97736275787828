import Carousel from "react-bootstrap/Carousel";
import React from "react";
import "./index.scss";
import call from "./images/Group 8113.png";
import tablet from "./images/Group 8115.png";
import plask from "./images/Group 8120.png";
import callcenter from "./images/Group 8124.png";
import car from "./images/Group 8122.png";
import opd from "./images/Group 8118.png";
import time from "./images/Group 8032.png";
import board from "./images/Group 8030.png";
import clock from "./images/Group 8034.png";

import "../carepalTreatment";
const SecureBenifits = () => {
  return (
    <>
      <div id="web-benifits" className="margine-top-benifits">
        <div className="container-fluid bg">
          <div className="heading-first-benifits">Features & Benefits</div>
          <div className="small-heading-prgf">
            One CarePal Secure Plan Covers upto 4 members of a family for 365
            days*
          </div>
          <div className="row-flex">
            <div className="card">
              <div className="col-lg-4">
                <img
                  className="image-secure-benifits"
                  src={call}
                  alt="jhckdb"
                />
              </div>
              <div className="col-lg-8 txt card-text-benifits">
                <strong>Unlimited Teleconsultations</strong>
                <br />
                <div>24x7 Support, 16 languages</div>
              </div>
            </div>
            <div className="card">
              <div className="col-lg-4">
                <img
                  className="image-secure-benifits"
                  src={tablet}
                  alt="jhckdb"
                />
              </div>
              <div className="col-lg-8 txt card-text-benifits">
                <strong>Upto 15% Discounts </strong> <br />
                <div>On Pharmacy + Vouchers</div>
              </div>
            </div>
            <div className="card">
              <div className="col-lg-4">
                <img
                  className="image-secure-benifits"
                  src={plask}
                  alt="jhckdb"
                />
              </div>
              <div className="col-lg-8 txt card-text-benifits">
                <strong>Upto 70% Discount</strong> <br />
                <div>On Diagnostics + Vouchers</div>
              </div>
            </div>
          </div>
          <div className="row-flex">
            <div className="card">
              <div className="col-lg-4">
                <img
                  className="image-secure-benifits"
                  src={callcenter}
                  alt="jhckdb"
                />
              </div>
              <div className="col-lg-8 txt card-text-benifits">
                <strong>24x7</strong> <br />
                <div>Claims Helpdesk</div>
              </div>
            </div>
            <div className="card">
              <div className="col-lg-4">
                <img className="image-secure-benifits" src={car} alt="jhckdb" />
              </div>
              <div className="col-lg-8 txt card-text-benifits">
                <strong>₹1 Lakh</strong> <br />
                <div>Personal Accident Insurance</div>
              </div>
            </div>
            <div className="card">
              <div className="col-lg-4">
                <img className="image-secure-benifits" src={opd} alt="jhckdb" />
              </div>
              <div className="col-lg-8 txt card-text-benifits">
                <strong>OPD Vouchers</strong> <br />
                <div>For Network Hospitals</div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="heading-first-benifits">
            Why Is CarePal Secure A Smart Choice?
          </div>
          <br />
        </div>

        <div className="row-flex posi">
          <div className="card">
            <div className="col-lg-4">
              <img className="image-secure-benifits" src={time} alt="jhckdb" />
            </div>
            <div className="col-lg-8 txt card-text-benifits">
              <strong>No Waiting Period</strong> <br />
              <div>Your healthcare coverage begins from day 0</div>
            </div>
          </div>
          <div className="card">
            <div className="col-lg-4">
              <img className="image-secure-benifits" src={board} alt="jhckdb" />
            </div>
            <div className="col-lg-8 txt card-text-benifits">
              <strong>Customised Plans</strong> <br />
              <div>Get affordable and personalised healthcare plans</div>
            </div>
          </div>
          <div className="card">
            <div className="col-lg-4">
              <img className="image-secure-benifits" src={clock} alt="jhckdb" />
            </div>
            <div className="col-lg-8 txt card-text-benifits">
              <strong>24x7 Coverage</strong> <br />
              <div>Avail related healthcare benefits anytime you need</div>
            </div>
          </div>
        </div>
      </div>
      <div id="mobile-benifits">
        <div
          className="container-fluid bg"
          style={{ paddingTop: "30px", paddingBottom: "30px" }}
        >
          <div className="heading-first-benifits-m">Features & Benefits</div>
          <div className="small-heading-prgf-m">
            One CarePal Secure Plan Covers up to 4 members of a{" "}
          </div>
          <div className="small-heading-prgf-m"> family for 365 days*</div>
          <Carousel style={{ marginBottom: "30px", marginTop: "30px" }}>
            <Carousel.Item interval={1000}>
              <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <div
                  className="row align-items-center"
                  style={{ height: "80px" }}
                >
                  <div className="col-4 col-sm-4">
                    <img
                      className="image-secure-benifits"
                      src={call}
                      alt="jhckdb"
                    />
                  </div>
                  <div className="col-8 col-sm-8 text-l card-text-benifits">
                    <strong>Unlimited Teleconsultations</strong>
                    <br />
                    <div>24x7 Support, 16 languages</div>
                  </div>
                </div>
              </div>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={500}>
              <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <div
                  className="row align-items-center"
                  style={{ height: "80px" }}
                >
                  <div className="col-4 col-sm-4">
                    <img
                      className="image-secure-benifits"
                      src={tablet}
                      alt="jhckdb"
                    />
                  </div>
                  <div className="col-8 col-sm-8 text-l card-text-benifits">
                    <strong>Upto 15% Discounts</strong>
                    <br />
                    <div>On Pharmacy + Vouchers</div>
                  </div>
                </div>
              </div>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <div
                  className="row align-items-center"
                  style={{ height: "80px" }}
                >
                  <div className="col-4 col-sm-4">
                    <img
                      className="image-secure-benifits"
                      src={plask}
                      alt="jhckdb"
                    />
                  </div>
                  <div className="col-8 col-sm-8 text-l card-text-benifits">
                    <strong>Upto 70% Discount</strong>
                    <br />
                    <div>On Diagnostics + Vouchers</div>
                  </div>
                </div>
              </div>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <div
                  className="row align-items-center"
                  style={{ height: "80px" }}
                >
                  <div className="col-4 col-sm-4">
                    <img
                      className="image-secure-benifits"
                      src={callcenter}
                      alt="jhckdb"
                    />
                  </div>
                  <div className="col-8 col-sm-8 text-l card-text-benifits">
                    <strong>24x7</strong>
                    <br />
                    <div>Claims Helpdesk</div>
                  </div>
                </div>
              </div>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <div
                  className="row align-items-center"
                  style={{ height: "80px" }}
                >
                  <div className="col-4 col-sm-4">
                    <img
                      className="image-secure-benifits"
                      src={car}
                      alt="jhckdb"
                    />
                  </div>
                  <div className="col-8 col-sm-8 text-l card-text-benifits">
                    <strong>₹1 Lakh</strong>
                    <br />
                    <div> Personal Accident Insurance</div>
                  </div>
                </div>
              </div>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <div
                  className="row align-items-center"
                  style={{ height: "80px" }}
                >
                  <div className="col-4 col-sm-4">
                    <img
                      className="image-secure-benifits"
                      src={opd}
                      alt="jhckdb"
                    />
                  </div>
                  <div className="col-8 col-sm-8 text-l card-text-benifits">
                    <strong>OPD Vouchers</strong>
                    <br />
                    <div>For Network Hospitals</div>
                  </div>
                </div>
              </div>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          </Carousel>

          <div className="heading-first-benifits-m">
            Why Is CarePal Secure A Smart Choice?
          </div>
          <br />
          {/* <div className='small-heading-prgf-m'>With easy and pocket-friendly EMIs, we aim to make elective treatments affordable for one and all.</div> */}

          <Carousel className="stats-carousel" style={{ marginTop: "30px" }}>
            <Carousel.Item interval={1000}>
              <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <div
                  className="row align-items-center"
                  style={{ height: "80px" }}
                >
                  <div className="col-4 col-sm-4">
                    <img
                      className="image-secure-benifits"
                      src={time}
                      alt="jhckdb"
                    />
                  </div>
                  <div
                    className="col-8 col-sm-8 text-l card-text-benifits"
                    style={{ lineHeight: "20px" }}
                  >
                    <strong>Customised Plans</strong> <br />
                    <div>Get affordable and personalised healthcare plans</div>
                  </div>
                </div>
              </div>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={500}>
              <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <div
                  className="row align-items-center"
                  style={{ height: "80px" }}
                >
                  <div className="col-4 col-sm-4">
                    <img
                      className="image-secure-benifits"
                      src={board}
                      alt="jhckdb"
                    />
                  </div>
                  <div
                    className="col-8 col-sm-8 text-l card-text-benifits"
                    style={{ lineHeight: "20px" }}
                  >
                    <strong>Customised Plans</strong> <br />
                    <div>Get affordable and personalised healthcare plans</div>
                  </div>
                </div>
              </div>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <div
                  className="row align-items-center"
                  style={{ height: "80px" }}
                >
                  <div className="col-4 col-sm-4">
                    <img
                      className="image-secure-benifits"
                      src={clock}
                      alt="jhckdb"
                    />
                  </div>
                  <div
                    className="col-8 col-sm-8 text-l card-text-benifits"
                    style={{ lineHeight: "20px" }}
                  >
                    <strong>24x7 Coverage</strong> <br />
                    <div>
                      Avail related healthcare benefits anytime you need
                    </div>
                  </div>
                </div>
              </div>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>

      <div className="container bg2 d-flex justify-content-center">
        <div className="row">
          <div className="button-set-below-flex button-set-below-flex">
            <a
              style={{ width: "311px" }}
              href='https://www.carepalsecure.com/' 
              className="hb-btn hb-btn11"
            >
              Read More
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecureBenifits;
