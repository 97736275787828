import Carousel from 'react-bootstrap/Carousel';
import pic from "./images/MaskGroup.png"
import fund from "./images/Group 8276.png"
import firstpic from "./images/Mask Group 171.png"
import "./firstsection.scss";
import circle from "./images/Group 7745.png";
import solid from "./images/Group 7749.png";
import hollow from "./images/Group 7748.png"
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import { Container, Row, Col } from 'react-bootstrap';
import dotcircle  from "./images/Dotted circle.png"



function FirstSection() {
  return (

    <div className='banner hb'>
      <Carousel fade controls={false}>
        <Carousel.Item interval={2000}>
          <div className='banner hb'>
           <LazyLoadImage
              className="d-block w-100 hb-img"
              src={pic}
              alt="First slide"
            />
            <div className='hb-overlay'></div>
            <Container className='hb-content'>
              <Row>
                <Col lg={8} className="hb-left ">
                  <div className='hb-left-header hb-left-header-sm mtb'>ImpactGuru</div>
                  <div className='hb-left-header hb-left-header-main mt-md-2 mt-4'>Making Healthcare Affordable, <br className='d-none d-md-block' /> One Fundraiser At A Time</div>
                  <div>
                    <a href='https://www.impactguru.com/' className='hb-btn' >Read More</a>
                  </div>
                </Col>
                <Col lg={4} className="hb-content-right" style={{position:"relative"}}>
                  <div className='hb-content-right-wp'>
                  <img src={circle} className="circle1 ps-solid"/>
                    <img  src={solid} className="solid1"/>
                    <img src={hollow}   className="hollow1"/>
                    <LazyLoadImage src={dotcircle} className="circledot" />
                   <LazyLoadImage src={firstpic} className='hb-content-img'  alt=""/>
                    <div className='hb-content-matrix'>
                      <LazyLoadImage className='hb-content-matrix-img' src='./Assets for CarePal website/groups_black_24dp.png' alt="" />
                      <div className='hb-content-matrix-header'>30+ Lakhs</div>
                      <p className='hb-content-matrix-content'>Donor Community</p>

                      <LazyLoadImage className='hb-content-matrix-img mt-2 mt-md-4' src={fund}  alt="" />
                      <div className='hb-content-matrix-header'>30,000+</div>
                      <p className='hb-content-matrix-content'>Treatments Funded</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

          </div>

        </Carousel.Item>


        <Carousel.Item interval={2000}>
          <div className='banner hb'>
            <LazyLoadImage
              className="d-block w-100 hb-img"
              src={pic}
              alt="First slide"
            />
            <div className='hb-overlay'></div>
            <Container className='hb-content'>
              <Row>
                <Col lg={8} className="hb-left mtb">
                  <div className='hb-left-header hb-left-header-sm mtb'>CarePal Secure</div>
                  <div className='hb-left-header hb-left-header-main mt-2'>Helping You Make Secure <br className='d-none d-md-block' /> Financial Decisions For A <br className='d-none d-md-block' />Healthier Tomorrow</div>
                  <div>
                    <a href='https://www.carepalsecure.com/' className='hb-btn' >Read More</a>
                  </div>
                </Col>
                <Col lg={4} className="hb-content-right" >
                  <div className='hb-content-right-wp'>
                      <img src={circle} className="circle1 ps-solid"/>
                    <img  src={solid} className="solid1"/>
                    <img src={hollow}   className="hollow1"/>
                     <LazyLoadImage src={dotcircle} className="circledot" />
                    <LazyLoadImage src="./Assets for CarePal website/Mask Group 128.png" className='hb-content-img'  alt=""/>

                    <div className='hb-content-matrix'>
                      <LazyLoadImage  className='hb-content-matrix-img' src='./Assets for CarePal website/groups_black_24dp.png'  alt="" />
                      <div className='hb-content-matrix-header'>10,000</div>
                      <p className='hb-content-matrix-content'>Customers</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

          </div>

        </Carousel.Item>



        <Carousel.Item interval={2000}>
          <div className='banner hb'>
            <LazyLoadImage
              className="d-block w-100 hb-img"
              src={pic}
              alt="First slide"
            />
            <div className='hb-overlay'></div>
            <Container className='hb-content'>
              <Row>
                <Col lg={8} className="hb-left mtb">
                  <div className='hb-left-header hb-left-header-sm mtb'>CarePal Money</div>
                  <div className='hb-left-header hb-left-header-main mt-2'>Making quality healthcare <br className='d-none d-md-block' />accessible through quick and <br className='d-none d-md-block' />affordable loans, starting <br className='d-none d-md-block' />at 0% EMI!</div>
                  <div>
                    <a href='https://carepalmoney.com/' className='hb-btn' >Read More</a>
                  </div>
                </Col>
                <Col lg={4} className="hb-content-right">
                  <div className='hb-content-right-wp'>
                      <img src={circle} className="circle1"/>
                    <img  src={solid} className="solid1"/>
                    <img src={hollow}   className="hollow1"/>
                     <LazyLoadImage src={dotcircle}className="circledot" />
                    <LazyLoadImage src="./Assets for CarePal website/Mask Group 129.png" className='hb-content-img'  alt="" />
                    <div className='hb-content-matrix'>
                      <LazyLoadImage className='hb-content-matrix-img hb-content-matrix-img-1' src='./Assets for CarePal website/payments_black_24dp.png'  alt=""/>
                      <div className='hb-content-matrix-header'>0%</div>
                      <p className='hb-content-matrix-content'>EMI Loans</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

          </div>

        </Carousel.Item>


      </Carousel >
    </div >


  );
}

export default FirstSection;