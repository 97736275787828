
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from './component/Header/Header';
import Footer from './component/Footer/Footer';
import Home from './Pages/Home/Home';
import AboutUs from './Pages/About/AboutUs';
import ImpactGuru from './Pages/ImpactGuru/ImpactGuru';
import CarepalMoney from './Pages/CarepalMoney/CarepalMoney';
import CarepalSecure from './Pages/CarepalSecure';
import TermsofUse from "./component/TermsofUse";
import './App.scss';

import GotoTop from "./component/GotoTo/GotoTop"

function App() {
  return (
    <div>
    <Router>
    
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>} exact={true}/>
        <Route path='/impactguru' element={<ImpactGuru/>}/>
        <Route path='/about-us' element={<AboutUs/>}/>
        <Route path='/carpalmoney' element={<CarepalMoney/>}/>
        <Route path='/carepalsecure' element={<CarepalSecure/>}/>
        <Route  path="/terms-of-use"  element={<TermsofUse/>} />
      </Routes>
      <GotoTop/>
      {/* <ScrollTop/> */}
      <Footer />

    </Router>
    </div>
  );
}

export default App;
