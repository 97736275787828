import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import logo from "./carepallogo-svg.png";
import mail from "./mail.png";
import social from "./Group6952.png";

const Footer = () => {
  return (
    <footer className="pb-4 pt-5">
      <div className="container text-center-footer">
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <img src={logo} className="f-img mb-3" alt="" />
            <p>Healthcare financing made easy!</p>
            <p>
              Corporate office 3rd Floor, Vaman Centre, Makwana Rd, Gamdevi,
              Marol, Andheri East, Mumbai, Maharashtra 400059
            </p>
          </div>
          <div className="col-lg-7 col-sm-12">
            <div className="row pb-4">
              <div className="col-lg-4 col-12">
                <div>
                  <img src={social} style={{ width: "200px" }} alt="" />
                </div>
              </div>
              <div
                className="col-lg-1 	d-sm-none d-md-block mobile-block"
                style={{ textAlign: "center" }}
              >
                |
              </div>
              <div className="col-lg-6 col-12 pd">
                <div>
                  <img src={mail} style={{ width: "30px" }} alt="" /> &nbsp;
                  <a
                    href="mailto:info@carepalgroup.com"
                    style={{ color: "#ffff", textDecoration: "none" }}
                  >
                    info@carepalgroup.com
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-6">
                <div>
                  <Link
                    to="/terms-of-use"
                    style={{ color: "#ffffff", textDecoration: "none" }}
                  >
                    Terms Of Use
                  </Link>
                </div>
              </div>
              {/* <div className="col-lg-2">|</div> */}
              <div className="col-lg-6">
                {/* <img src={social} style={{ width: "200px" }} alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
