import React from 'react'
import "./index.css"
import tableweb  from "./imagess/Table.png"
import tablemobile from "./imagess/Table Mobile.png"
const CarepallInstallment = () => {
  return (
    <div className='container con-p-instl '>
           <div className='heading-table-money'>Payback In Easy Installments</div>
           <div className='second-headline-table'>With easy and pocket-friendly EMIs, CarePal Money aims to make non-critical <br/>surgery treatments affordable for one and all</div>
       
          <div className='flex-instalment web '>
                <img className='table-w-lg' s src={tableweb}  alt=""/>
          </div>
          <div className='flex-instalment mobile'>
                <img style={{width:"300px"}} src={tablemobile}  alt=""/>
          </div>
           
         <div className='flex-table' style={{marginBottom:"30px"}}>
         <a style={{width:"311px"}} href='javascript:void(0);' className='hb-btn1 hb-btn'  >Know More</a>
         </div>
    </div>
  )
}

export default CarepallInstallment