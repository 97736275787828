import Carousel from "react-bootstrap/Carousel";
import award1 from "./images/award 01.png";
import award2 from "./images/award 02.png";
import award3 from "./images/award 03.png";
import award4 from "./images/award 04.png";
import award5 from "./images/award 05.png";
import award6 from "./images/award 06.png";
import award7 from "./images/award 07.png";
import award8 from "./images/award 08.png";
import award9 from "./images/award 09.png";
import { Container, Row, Col } from "react-bootstrap";

import "./award.scss";
function CarepalAward() {
  return (
    <div>
      <Container>
        <div className="award-heading">Awards & Recognition</div>
        <Row>
          <Col lg={12}>
            <Carousel
              id="web-award-carosoul"
              style={{ marginBottom: "60px" }}
              className="m-awards-carousel web-award-m"
              controls="false"
              indicators={false}
            >
              <Carousel.Item interval={3000}>
                <div className="row ">
                  <div className="col-md-4 col-sm-4 col-lg-4 ">
                    <div style={{ textAlign: "center" }}>
                      <img
                        className="award-pic-size"
                        src={award1}
                        alt="First slide"
                      />

                      <div className="px-3 award-carousel-content">
                        <div className="text-align-award-content px-20-f">
                          BW Healthcare World 40 Under 40
                        </div>
                        <div className="text-align-award-content px-16-f">
                          Khushboo Jain Featured In The 2021 List
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 ">
                    <div style={{ textAlign: "center" }}>
                      <img
                        className="award-pic-size"
                        src={award2}
                        alt="First slide"
                      />

                      <div className="text-align-award-content px-20-f">
                        BW Healthcare World 40 Under 40
                      </div>
                      <div className="text-align-award-content  px-16-f">
                        Piyush Jain Featured In The 2021 List
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 ">
                    <div style={{ textAlign: "center" }}>
                      <img
                        className="award-pic-size"
                        src={award3}
                        alt="First slide"
                      />
                      <div className="px-3 award-carousel-content">
                        <div className="text-align-award-content px-20-f">
                          The Economic Times Intelligent Health & Tech Awards
                          2020{" "}
                        </div>
                        <div className="text-align-award-content  px-16-f">
                          Best Technology Solution For Social Good
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className="row ">
                  <div className="col-md-4 col-sm-4 col-lg-4 ">
                    <div style={{ textAlign: "center" }}>
                      <img
                        className="award-pic-size"
                        src={award4}
                        alt="First slide"
                      />
                      <div className="px-3 award-carousel-content">
                        <div className="text-align-award-content px-20-f">
                          NITI Aayog & United Nations - 2019 Women Transforming
                          India Awards
                        </div>
                        <div className="text-align-award-content  px-16-f">
                          Khushboo Jain Featured In Winning Top 15
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 ">
                    <div style={{ textAlign: "center" }}>
                      <img
                        className="award-pic-size"
                        src={award5}
                        alt="First slide"
                      />

                      <div className="text-align-award-content px-20-f">
                        Fortune India 40 Under 40
                      </div>
                      <div className="text-align-award-content  px-16-f">
                        Piyush & Khushboo Jain Featured In 2019 List
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 ">
                    <div style={{ textAlign: "center" }}>
                      <img
                        className="award-pic-size"
                        src={award6}
                        alt="First slide"
                      />
                      <div className="px-3 award-carousel-content">
                        <div className="text-align-award-content px-20-f">
                          Government Of India, National Commission For
                          Minorities
                        </div>
                        <div className="text-align-award-content  px-16-f">
                          Recognition For Khushboo Jain For Contributions In The
                          Social Impact Sector
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className="row ">
                  <div className="col-md-4 col-sm-4 col-lg-4 ">
                    <div style={{ textAlign: "center" }}>
                      <img
                        className="award-pic-size"
                        src={award7}
                        alt="First slide"
                      />
                      <div className="px-3 award-carousel-content">
                        <div className="text-align-award-content px-20-f">
                          PaisaBazaar's Women In Fintech Awards
                        </div>
                        <div className="text-align-award-content  px-16-f">
                          Social Woman Entrepreneur
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 ">
                    <div style={{ textAlign: "center" }}>
                      <img
                        className="award-pic-size"
                        src={award8}
                        alt="First slide"
                      />

                      <div className="text-align-award-content px-20-f">
                        TechCircle 2018 Public Choice Award
                      </div>
                      <div className="text-align-award-content  px-16-f">
                        Best Social Impact Startup
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 ">
                    <div style={{ textAlign: "center" }}>
                      <img
                        className="award-pic-size"
                        src={award9}
                        alt="First slide"
                      />

                      <div className="px-3 award-carousel-content">
                        <div className="text-align-award-content px-20-f">
                          The Economic Times & Femina
                        </div>
                        <div className="text-align-award-content  px-16-f">
                          Khushboo Jain Featured As A Leading Woman In Business
                          2021 Lis
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
            <Carousel
              id="mobile-award-carosoul"
              className="m-awards-carousel mobile-award-m"
              controls="false"
              indicators={false}
            >
              <Carousel.Item interval={1000}>
                <div className="col-sm-12 ">
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="award-pic-size"
                      src={award1}
                      alt="First slide"
                    />

                    <div className="px-3 award-carousel-content">
                      <div className="text-align-award-content px-20-f">
                        BW Healthcare World 40 Under 40
                      </div>
                      <div className="text-align-award-content px-16-f">
                        Khushboo Jain Featured In The 2021 List
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="col-sm-12">
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="award-pic-size"
                      src={award2}
                      alt="First slide"
                    />
                    <div className="px-3 award-carousel-content">
                      <div className="text-align-award-content px-20-f">
                        BW Healthcare World 40 Under 40
                      </div>
                      <div className="text-align-award-content  px-16-f">
                        Piyush Jain Featured In The 2021 List
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="col-sm-12 ">
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="award-pic-size"
                      src={award3}
                      alt="First slide"
                    />

                    <div className="px-3 award-carousel-content">
                      <div className="text-align-award-content px-20-f">
                        The Economic Times Intelligent Health & Tech Awards 2020{" "}
                      </div>
                      <div className="text-align-award-content  px-16-f">
                        Best Technology Solution For Social Good
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="col-sm-12">
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="award-pic-size"
                      src={award4}
                      alt="First slide"
                    />

                    <div className="px-3 award-carousel-content">
                      <div className="text-align-award-content px-20-f">
                        NITI Aayog & United Nations - 2019 Women Transforming
                        India Awards
                      </div>
                      <div className="text-align-award-content  px-16-f">
                        Khushboo Jain Featured In Winning Top 15
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="col-sm-12">
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="award-pic-size"
                      src={award5}
                      alt="First slide"
                    />
                    <div className="px-3 award-carousel-content">
                      <div className="text-align-award-content px-20-f">
                        Fortune India 40 Under 40
                      </div>
                      <div className="text-align-award-content  px-16-f">
                        Piyush & Khushboo Jain Featured In 2019 List
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="col-sm-12">
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="award-pic-size"
                      src={award6}
                      alt="First slide"
                    />

                    <div className="px-3 award-carousel-content">
                      <div className="text-align-award-content px-20-f">
                        Government Of India, National Commission For Minorities
                      </div>
                      <div className="text-align-award-content  px-16-f">
                        Recognition For Khushboo Jain For Contributions In The
                        Social Impact Sector
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="col-sm-12">
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="award-pic-size"
                      src={award7}
                      alt="First slide"
                    />
                    <div className="px-3 award-carousel-content">
                      <div className="text-align-award-content px-20-f">
                        PaisaBazaar's Women In Fintech Awards
                      </div>
                      <div className="text-align-award-content  px-16-f">
                        Social Woman Entrepreneur
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="col-sm-12">
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="award-pic-size"
                      src={award8}
                      alt="First slide"
                    />
                    <div className="px-3 award-carousel-content">
                      <div className="text-align-award-content px-20-f">
                        TechCircle 2018 Public Choice Award
                      </div>
                      <div className="text-align-award-content  px-16-f">
                        Best Social Impact Startup
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="col-sm-12">
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="award-pic-size"
                      src={award9}
                      alt="First slide"
                    />
                    <div className="px-3 award-carousel-content">
                      <div className="text-align-award-content px-20-f">
                        The Economic Times & Femina
                      </div>
                      <div className="text-align-award-content  px-16-f">
                        Khushboo Jain Featured As A Leading Woman In Business
                        2021 Lis
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CarepalAward;
