import React from 'react'
import SecureAbout from '../../component/CarepalsecureAbout'
import CarepalKnowmore from '../../component/CarepalsecureKnowmore'
import SecureBenifits from '../../component/CarepalsecureBenifits'


const CarepalSecure = () => {
  return (
    <>     
        <CarepalKnowmore/>
        <SecureAbout/>
        <SecureBenifits/>
  
    </>
  )
}

export default CarepalSecure