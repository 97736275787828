import React from "react";
import "./knowmoresecure.scss";
import dots from "./images/Group 7693.svg";
import pic from "./images/Mask Group 95.png";
import round from "./images/circle.png";
import hollow from "./images/hollow.png";
import solid from "./images/solid.png";
import ractangle from "./images/Rectangle 3232.png";
import { Container, Row, Col } from "react-bootstrap";
import group from "./images/groupsblack.png";

const CarepalKnowmore = () => {
  return (
    <div className="banner hb">
      <div className="hb-overlay1 hb-overlay"></div>
      <Container className="hb-content">
        <Row>
          <Col lg={8} className="hb-left">
            <div
              className="hb-left-header hb-left-header-sm"
              style={{ color: "#fffff" }}
            ></div>
            <div
              className="hb-left-header hb-left-header-main mt-2"
              style={{ color: "#393939" }}
            >
              Helping You Make Secure <br className="d-none d-md-block" />{" "}
              Financial Decisions For A<br className="d-none d-md-block" />{" "}
              Healthier Tomorrow
            </div>
            <div>
              <a  href='https://www.carepalsecure.com/' className="hb-btn1 hb-btn">
                Know More
              </a>
            </div>
          </Col>

          <Col lg={4} className="hb-content-right m-t-mny">
            <div className="hb-content-right-wp">
              <img src={pic} className="hb-content-img" alt="" />
              <img src={ractangle} className="shadow-back-main" alt="" />
              <img src={solid} className="top-solid" />
              <img src={hollow} className="top-hollow" />
              <img src={round} className="top-circle" />
              <img className="dots-align" src={dots} alt="" />
              <div className="hb-content-matrix1 hb-content-matrix">
                <img className="hb-content-matrix-img" src={group} alt="" />
                <div
                  className="hb-content-matrix-header1 hb-content-matrix-header"
                  style={{ color: "#fffff" }}
                >
                  10,000
                </div>
                <p
                  className="hb-content-matrix-content1 hb-content-matrix-content"
                  style={{ color: "#fffff" }}
                >
                  Customers
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CarepalKnowmore;
