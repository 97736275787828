import React from 'react'
import LeaderShip from '../../component/LeaderShip/LeaderShip'
import AboutUssece1 from '../../component/AboutusSection1'
import AboutSection2 from '../../component/AboutSection2'
import CarepalAward from '../../component/CarepalsecureAward'


const AboutUs = () => {
  return (
    <>
    
    <AboutUssece1/>
    <AboutSection2/>
    <LeaderShip/>
    <CarepalAward/>

    </>
  )
}

export default AboutUs