import React from "react";
// import leftpic from "./images/Mask Group 96.png";
// import rightpic from "./images/Mask Group 92.png";
import leftpic from "./images/1.png";
import rightpic from "./images/2.png";
import dots from "./images/Group 7693.svg";
import menicons from "./images/Group 8127.png";
import target from "./images/Group 11276.png";
import solid from "./images/solid.png";
import hollow from "./images/hollow.png";
import "./carepalabout.scss";
import rectangle from "../../Pages/images/rectangle.png";

const SecureAbout = () => {
  return (
    //     <div className='container' style={{paddingTop:"50px"}}>
    //     <div className='row'>
    //         <div className='col-lg-5 col-md-5 col-sm-12 postion-relative-block height-block-1' style={{ backgroundColor:"#ffff"}}>

    //              <img className='left-pic-main-secure' src={leftpic}  alt=""/>
    //              <img className='left-pic-main-dots-secure' src={dots}  alt=""/>
    //              <img src={rectangle} className="secure-shadow"  alt=""/>
    //         </div>
    //         <div id="1" className='col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-center card-design-style m-top m-l-scure-about'style={{height:"300px", backgroundColor:"#ffff"}}>
    //             <div className='headindtext-second revers-text-box'><img className='icon-size-a revers-text-box' src={menicons}  alt=""/>&nbsp;About Us</div><br/>
    //             <div className='paragrap-second revers-text-box'>
    //             With CarePal Secure, make your healthcare protection a priority. Our experts simplify all the plan benefits and covers; helping you make your health and life's financial decisions easy and simple. <br/><br/>
    //              Focusing on transparency and customer satisfaction, we prioritize your health with health protection policies that are just the right fit for you. Our medical protection plans are designed to provide customers with a convenient and hassle-free experience at times of need.
    //             </div>
    //         </div>

    //     </div>
    //     <div className='row reverse-in-mobile'>
    //          <div className='col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-center card-design-style'style={{ backgroundColor:"#ffff"}}>
    //            <div  className='headindtext-second revers-text-box'><img className='icon-size-a ' src={target}  alt=""/>&nbsp;Our Mission </div>
    //            <div className='paragrap-second revers-text-box'>To provide an unparalleled healthcare protection experience <br/> as the most reliable health insurance partner.
    //           </div>
    //          </div>
    //          <div className='col-lg-5 col-md-5 col-sm-12 postion-relative-block card-design-style m-bottom m-l-scure-about-2  ml-t-about-2'style={{ backgroundColor:"#ffff"}}>
    //             <img className='right-pic-main-secure' src={rightpic}  alt=""/>
    //             <img className='right-pic-main-dots-secure'  src={dots}  alt=""/>
    //             <img src={rectangle} className="secure-shadow2"  alt=""/>
    //          </div>
    //     </div>

    // </div>
    <section id="carepalAbout">
      <div className="container-fluid container-detail-size">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 postion-relative-block col-width-clr">
              {/* <img className="left-pic-main-dots-money" src={dots} alt="" /> */}
              <img className="left-pic-main-money" src={leftpic} alt="" />
              {/* <img src={rectangle} className="about-shadow" alt="" />
              <img src={solid} className="solid-a" /> */}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 align-flex-paragrap col-width-clr m-l-mny">
              <div className="headindtext-second-money revers-text-box">
                <img style={{ width: "40px" }} src={menicons} alt="" />
                &nbsp;About Us
              </div>
              <div className="paragrap-second-money revers-text-box">
                With CarePal Secure, make your healthcare protection a priority.
                Our experts simplify all the plan benefits and covers; helping
                you make your health and life's financial decisions easy and
                simple. <br />
                <br />
                Focusing on transparency and customer satisfaction, we
                prioritize your health with health protection policies that are
                just the right fit for you. Our medical protection plans are
                designed to provide customers with a convenient and hassle-free
                experience at times of need.
              </div>
            </div>
          </div>
          <div className="row reverse-in-mobile">
            <div className="col-lg-6 col-md-6 col-sm-12 align-flex-paragrap col-width-clr ">
              <div className="headindtext-second-money revers-text-box">
                <img style={{ width: "40px" }} src={target} alt="" /> &nbsp;Our
                Mission
              </div>
              <div className="paragrap-second-money revers-text-box">
                To provide an unparalleled healthcare protection experience{" "}
                <br /> as the most reliable health insurance partner.
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 postion-relative-block col-width-clr m-l-mny2 m-t">
              {/* <img className="right-pic-main-dots-money" src={dots} alt="" /> */}
              <img className="right-pic-main-money" src={rightpic} alt="" />
              {/* <img src={hollow} className="hollow-a" />
              <img src={solid} className="solid-b" />
              <img src={rectangle} className="about-shadow2" alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecureAbout;
