// import React, { useEffect, useState } from "react";
// import { FaArrowUp } from "react-icons/fa";
// import top from "./images/Ellipse 106.png";
// import arrow from "./images/toparrow.png";

// const GotoTop = () => {
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     window.addEventListener("scroll", () => {
//       if (window.scrollY > 80) {
//         setIsVisible(true);
//       } else {
//         setIsVisible(false);
//       }
//     });
//   }, []);

//   const scrollup = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };

//   return (
//     <div>
//       {isVisible && (
//         <>
//           <img
//             src={top}
//             className="rotate circle-rotate"
//             onClick={scrollup}
//           />
//           <img src={arrow}  className="arrow"
//             onClick={scrollup}
//             />
//         </>
//       )}
//     </div>
//     // position: fixed;
//     // bottom: 58px;
//     // right: 56px;
//     // font-size: 51px;
//     // width: 36px;
//     // background-color: red;
//     // border-radius: 16px;

//   );
// };

// export default GotoTop;

import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import "./GoTo.scss";

// const GotoTop = () => {
//   const [showScroll, setShowScroll] = useState(false);
//   const [scrollProgress, setScrollProgress] = useState(0);

//   const checkScrollTop = () => {
//     if (!showScroll && window.pageYOffset > 400) {
//       setShowScroll(true);
//     } else if (showScroll && window.pageYOffset <= 400) {
//       setShowScroll(false);
//     }

//     const winScroll = document.documentElement.scrollTop;
//     const height =
//       document.documentElement.scrollHeight -
//       document.documentElement.clientHeight;
//     const scrolled = (winScroll / height) * 100;
//     setScrollProgress(scrolled);
//   };

//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", checkScrollTop);
//     return () => window.removeEventListener("scroll", checkScrollTop);
//   }, []);

//   return (
//     <div className="scroll-to-top">
//       {showScroll && (
//         <button className="scroll-btn" onClick={scrollToTop}>
//           <FaArrowUp />
//         </button>
//       )}
//       <div className="progress-bar" style={{ width: `${scrollProgress}%` }} />
//     </div>
//   );
// };

// export default GotoTop;
import arrow from "./images/toparrow.png";
const GotoTop = () => {


  let calcScrollValue = () => {

    let scrollProgress = document.getElementById("progress");
    let progressValue = document.getElementById("progress-value");
    let pos = document.documentElement.scrollTop;
    let calcHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    let scrollValue = Math.round((pos * 100) / calcHeight);
    if (pos > 100) {
      scrollProgress.style.display = "grid";
    } else {
      scrollProgress.style.display = "none";
    }
    scrollProgress.addEventListener("click", () => {
      document.documentElement.scrollTop = 0;
    });
    scrollProgress.style.background = `conic-gradient(#52BAE6 ${scrollValue}%, #ffffff ${scrollValue}%)`;
  };
  window.onscroll = calcScrollValue;
  window.onload = calcScrollValue;
  return (
    <div id="progress">
      <span id="progress-value"><img className="progress-img" src={ arrow }/></span>
    </div>
  );
};
export default GotoTop;
