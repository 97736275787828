import React from "react";
import ImpactguruSection1 from "../../component/ImpactguruSection1/ImpactguruSection1";
import Impactgurusection2 from "../../component/Impactgurusection2/impactgurusection2";
import SecondSection from "../../component/SecondSection/SecondSection";
import ImpactGuruSection4 from "../../component/ImpactguruSection4/ImpactGuruSection4";
import ImpactguruSection6 from "../../component/ImpactguruSection5/ImpactguruSection5";
import ImpactguruSection3 from "../../component/ImpactguruSection3";

const ImpactGuru = () => {
  return (
    <>
      <ImpactguruSection1 />
      <Impactgurusection2 />
      <ImpactguruSection3 />
      <ImpactGuruSection4 />
      <ImpactguruSection6 />
    </>
  );
};

export default ImpactGuru;
