import React from 'react';
import "./aboutsec.scss";
import { Link } from 'react-router-dom';
const AboutUssece1 = () => {
  return (
    
    <div className='container-fluid br-banner-size  text-position'>
      <div className='overlay-light-blue' ></div>
      <div className='position-relative'>
        <div className='banner-heading' >About Us</div>
        <div className='revert-homepgae'>
          <Link className='linkabt' to='/'> Home </Link>/<Link className='linkabt' to="/about-us">About Us</Link>
         </div>
      </div>
    </div>
  )
}

export default AboutUssece1