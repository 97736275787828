import React from "react";
import "./index.scss";

const TermsofUse = () => {
  return (
    <section id="Termofuse">
      <div className="container">
        <div className="row">
          <div className="p-3 text-mobile heading-term pt-3">Terms Of Use</div>
          <div className="p-3 text-mobile web-text">
            Thank you for using <span style={{color:"#1E75BB"}}>www.carepalgroup.com</span> (the “Website”).{" "}
          </div>
          <div className="p-3 text-mobile web-text">
            Please read the terms of use (“Terms of Use”) carefully. This is a
            legally binding agreement between you, the user, and Impact Guru
            Technology Ventures Private Limited, and all its affiliates and
            subsidiaries (“Affiliates/Subsidiaries”), establishing the terms and
            conditions under which this Website may be used/accessed. By
            accessing this Website, or any pages of the products thereof, and/or
            using the information or services provided on or via this Website,
            you agree to be bound by the terms and conditions of the Terms of
            Use.
          </div>

          <div className="p-3 text-mobile web-text">
            Carepal Group of is owned by Impact Guru Technology Ventures Private
            Limited is a company incorporated under the Companies Act, 2013
            having CIN No: U74900DL2015PTC409015 and its corporate office at 3rd
            Floor, A wing, Vaman Techno Centre, Marol Makwana Road, Andheri
            East, Mumbai, Maharashtra - 400059, India and is the owner of the
            Website i.e. www.carepalgroup.com . Carepal Group consists of Impact
            Guru and its Subsidiaries / Affiliates are collectively known as
            “Carepal Group”.
          </div>
          <div className="p-3 text-mobile web-text">
            Impact Guru is a leading crowdfunding platform in India that
            facilitates fundraising for a diverse range of medical causes for
            individuals, including but not limited to cancer, transplant,
            accident, rare diseases, or any medical emergency. Impact Guru with
            its subsidiary, Carepal Fintech Private Limited (“Carepal”), which
            operates as a healthcare lending platform under the Carepal Group of
            companies aims to create a sustainable healthcare ecosystem that
            benefits all.
          </div>
          <div className="p-3 text-mobile web-text">
            Carepal provides technology-based solutions for insurance and loan
            transactions between borrowers and lending partners through its two
            product brands, namely <b>“Carepal Money”</b> and{" "}
            <b className="pl-4"> “Carepal Secure”.</b> These two product brands
            are designed to support lending and insurance businesses under the
            Carepal Group of Companies.
          </div>
          <div className="p-3 text-mobile web-text">
            Our focus is to bridge the gap between accessible and affordable
            healthcare finance for millions of patients in India. With our
            combined expertise, we strive to establish a reliable, transparent,
            and cost-effective healthcare financing system that ensures patients
            receive the medical care they require without financial burden.
          </div>
          <div className="p-3 text-mobile web-text">
            <span>1.</span>&nbsp;&nbsp;
            <b className="pl-4">Carepal Secure</b> – Carepal Secure partners
            with healthcare organizations, institutions, hospitals, and
            diagnostic centers to provide healthcare plans and services to its
            customers. With Carepal Secure, you can access a variety of
            healthcare plans and products from Carepal's trusted partners. These
            plans are designed to meet your healthcare needs and ensure you in
            your healthcare assistance. To learn more about the terms of use and
            privacy policy, please visit the Carepal Secure website at
            www.carepalsecure.com.
          </div>
          <div className="p-3 text-mobile web-text">
            <span>2.</span>&nbsp;&nbsp;
            <b className="pl-4">Carepal Money </b>- Carepal Money partners with
            RBI regulated entities/Non-Banking Financial Companies (NBFCs) to
            provide loans to its customers. With Carepal Money, you have the
            opportunity to obtain Credit Facilities from RBI regulated entities
            /lending institutions, including NBFCs and other financial
            institutions. These lending institutions are collectively referred
            to as "Lenders". For more information on the terms of use and
            privacy policy, please visit the Carepal Money website at
            www.carepalmoney.com
          </div>
          <div className="p-3 text-mobile web-text">
            In the event of any conflict between the terms and conditions
            specific to a product or service and these general terms and
            conditions, the terms and conditions specific to the product or
            service shall prevail. It is important that you carefully review the
            terms and conditions applicable to each product or service before
            purchasing or using it.
          </div>
          <div className="p-3 text-mobile web-text">
            These Terms of Use govern your use of the Website and all content,
            services, and features offered on or through the Website. The
            Website may include links to third-party websites or resources,
            which are not under the control of Impact Guru or its
            Affiliates/Subsidiaries. You acknowledge and agree that Impact Guru
            and its Affiliates/Subsidiaries are not responsible or liable for
            any content, advertising, products, or other materials on or
            available from such third-party websites or resources.
          </div>
          <div className="p-3 text-mobile web-text">
            You agree to use the Website only for lawful purposes and in
            accordance with these Terms of Use. You also agree not to use the
            Website in any manner that could disable, overburden, damage, or
            impair the Website or interfere with any other party's use of the
            Website. You also agree not to attempt to gain unauthorized access
            to any portion of the Website, or any systems or networks connected
            to the Website, by hacking, password "mining," or any other
            illegitimate means.
          </div>
          <div className="p-3 text-mobile web-text">
            The Website and its content, including but not limited to text,
            graphics, images, videos, logos, trademarks, and software, are the
            property of Impact Guru Technology Ventures Private Limited or its
            Affiliates/Subsidiaries, or its licensors and are protected by
            copyright, trademark, and other intellectual property laws. You
            agree not to copy, modify, distribute, display, transmit, or create
            derivative works of any content or materials without the prior
            written consent of Impact Guru or its Affiliates/Subsidiaries or its
            licensors.
          </div>
          <div className="p-3 text-mobile web-text">
            You agree that any information or materials that you provide to
            Impact Guru or its Affiliates/Subsidiaries through this Website or
            otherwise is true, accurate, and complete to the best of your
            knowledge and belief. You also agree that you will not provide any
            false or misleading information or engage in any fraudulent activity
            through the Website.
          </div>
          <div className="p-3 text-mobile web-text">
            Impact Guru or its Affiliates/Subsidiaries may modify or revise
            these Terms of Use at any time without any notice to you. Your
            continued use of the Website after any such changes are made will
            constitute your acceptance of the new Terms of Use. Therefore, we
            recommend that you check this page regularly for any updates.
          </div>
          <div className="p-3 text-mobile web-text">
            In accordance with Information Technology Act 2000 and rules made
            there under, the name and contact details of the Grievance Officer
            are provided below who can be contacted with respect to any
            complaints or concerns including those pertaining to breach of Terms
            of Use, Privacy Policy, and other policies or questions:
          </div>
          <div className="p-3">Name: Vikas Kaul </div>
          <div className="p-3">
            Email: vikas.kaul@impactguru.com{" "}
          </div>
          <div className="p-3 ">
            Address: 3rd Floor, A wing, Vaman Techno Centre, Marol Makwana Road,
            Andheri East, Mumbai, Maharashtra - 400059,
          </div>
          <div className="p-3 pb-5">
            This Terms of Use was updated and became immediately effective on
            20th April 2023.
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsofUse;
