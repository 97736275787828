import React from 'react';
import "./impactguruSection5.css";
import hand from "./images/Group8276.png";
import publicpic from "./images/Group8272.png";
import clock from "./images/Group 8361.png"
import sndhand from "./images/loveic.png"

const ImpactguruSection6 = () => {
  return (
    <div className='container padding-for-succuse'>
      
      <h3 style={{textAlign:"center",marginBottom:"40px" ,paddingTop:"30px"}} className="heading-main-tr px-20">The Success Of Our Impact</h3>

       <div className='fl-defn'>
         <div className=''>
         
              <div>
                    <img className='img-icon' src={hand}   alt=""/>
              </div>
                
               <div className='revers-text-box-sucess-card'>
                       <h3 style={{color:"#1C75BC"}} className="mr-la-sec">2+ Lakh</h3>
                       <h6 >Fundraisers Created</h6>
               </div>
           
         </div>
         <div className=''>
      
              <div>
                       <img className='img-icon' src={publicpic}  alt=""/>
              </div>
              
              <div className='revers-text-box-sucess-card'>
                       <h3 style={{color:"#1C75BC"}} className="mr-la-sec">30+ Lakh</h3>
                       <h6>Donor Comminity</h6>
             </div>
           
         </div>
         <div>
              <div className=''>

                  <img className='img-icon' src={clock}  alt=""/>

              </div>
              
               <div className='revers-text-box-sucess-card'>
                  <h3 style={{color:"#1C75BC"}} className="mr-la-sec">₹1 Crore</h3>
                  <h6>Raised In 24 Hrs!</h6>
              </div>
         </div>
         <div className=''>
              <div>
                     <img className='img-icon' src={sndhand}  alt=""/>
              </div>
               
               <div className='revers-text-box-sucess-card'>
                  <h3 style={{color:"#1C75BC"}} className="mr-la-sec">2+ Lakh</h3>
                  <h6>Lives Impacted</h6>
               </div>
         </div>

       </div>
    </div>
  )
}

export default ImpactguruSection6