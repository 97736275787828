import React from "react";
// import reight from "./images/Mask Group 91.png";
import reight from "./images/2.png";
// import leftpic from "./images/Mask Group 186.png";
import leftpic from "./images/1.png";
import dots from "./images/Group7693.svg";
import "./carepalabout.scss";
import handicone from "./images/Group 8007.png";
import targeticone from "./images/Group 8008.png";
import rectangle from "../../Pages/images/rectangle.png";
import solid from "./images/solid.png";
import hollow from "./images/hollow.png"

const CarepalAbout = () => {
  return (
    <section id="carepalMoneyabout">
        <div className="container-fluid container-detail-size">
        <div className="container" style={{ backgroundColor: "#1C75BC" }}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 postion-relative-block col-width-clr">
              {/* <img className="left-pic-main-dots-money" src={dots} alt="" /> */}
              <img className="left-pic-main-money" src={leftpic} alt="" />
              {/* <img src={rectangle} className="about-shadow" alt="" />
              <img src={solid} className="solid-a" /> */}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 align-flex-paragrap col-width-clr m-l-mny">
              <div className="headindtext-second-money revers-text-box">
                <img style={{ width: "40px" }} src={handicone} alt="" />{" "}
                &nbsp;About CarePal Money
              </div>
              <div className="paragrap-second-money revers-text-box">
                CarePal Money is a lending marketplace that aims to bring
                holistic solutions in healthcare financing through
                patient-centric lending schemes. At CarePal Money, in
                partnership with RBI-regulated entities / NBFCs, get financial
                help to cover your healthcare needs with flexible payment
                options - that are just the right fit for you!
              </div>
            </div>
          </div>
          <div className="row reverse-in-mobile">
            <div className="col-lg-6 col-md-6 col-sm-12 align-flex-paragrap col-width-clr ">
              <div className="headindtext-second-money revers-text-box">
                <img style={{ width: "40px" }} src={targeticone} alt="" />{" "}
                &nbsp;Our Mission
              </div>
              <div className="paragrap-second-money revers-text-box">
                To provide affordable, accessible and customised healthcare
                lending <br /> solutions for all.
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 postion-relative-block col-width-clr m-l-mny2 m-t">
              {/* <img className="right-pic-main-dots-money" src={dots} alt="" /> */}
              <img className="right-pic-main-money" src={reight} alt="" />
              {/* <img src={hollow} className="hollow-a"/>
              <img src={solid} className="solid-b" />
              <img src={rectangle} className="about-shadow2" alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    
    </section>
  );
};

export default CarepalAbout;
