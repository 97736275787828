import React from 'react';
import Knowmore from '../../component/carepalKnowmore';
import CarepalAbout from '../../component/carepalAbout';
import CarepalTreatment from '../../component/carepalTreatment/';
import CarepallInstallment from '../../component/carepalInstallment';

const CarepalMoney = () => {
  return (
    <>
    <Knowmore/>
    <CarepalAbout/>
    <CarepalTreatment/>
    <CarepallInstallment/>
    </>
    
  )
}
export default CarepalMoney