import React from 'react';
import BB from "./images/1.png"
import CC from "./images/2.png"
import DD from "./images/3.png"
import EE from "./images/4.png"
import FF from "./images/5.png"
import GG from "./images/6.png"
import Carousel from 'react-bootstrap/Carousel';

import "./treatment.scss";

const CarepalTreatment = () => {
   return (
      <>
         <div className='container-fluid bg-money-treatment d-sm-none d-md-block con-trtm-pad' id="web-treatment-card-mobile" style={{ paddingTop: "30px" }} >
            <div className='d-sm-none d-md-block heading-treatment headindtext-second-money-ll' style={{ textAlign: "center" }} >Treatments Covered</div>
            <div className='row-flex'>
               <div className='card-test-money'>
                  <div className='card-top-part-money'>
                     <img className='icons' src={BB}  alt=""/>
                     <div className='card-bottom-part-money'>
                        <div className='card-text-treatment-list'>Cardiac Treatment</div>
                     </div>
                  </div>

               </div>
               <div className='card-test-money'>
                  <div className='card-top-part-money'>
                     <img className='icons' src={CC}  alt=""/>
                     <div className='card-bottom-part-money'>
                        <div className='card-text-treatment-list'>Orthopedic Surgery</div>
                     </div>
                  </div>

               </div>
               <div className='card-test-money'>
                  <div className='card-top-part-money'>
                     <img className='icons' src={DD}  alt=""/>
                     <div className='card-bottom-part-money'>
                        <div className='card-text-treatment-list'>IVF Treatment</div>
                     </div>
                  </div>

               </div>
               <div className='card-test-money'>
                  <div className='card-top-part-money'>
                     <img className='icons' src={EE}  alt=""/>
                     <div className='card-bottom-part-money'>
                        <div className='card-text-treatment-list'>Maternity Care</div>
                     </div>
                  </div>

               </div>
               <div className='card-test-money'>
                  <div className='card-top-part-money'>
                     <img className='icons' src={FF}  alt=""/>
                     <div className='card-bottom-part-money'>
                        <div className='card-text-treatment-list'>Ophthalmology</div>
                     </div>
                  </div>

               </div>
               <div className='card-test-money'>
                  <div className='card-top-part-money'>
                     <img className='icons' src={GG}  alt="" />
                     <div className='card-bottom-part-money'>
                        <div className='card-text-treatment-list'>ENT</div>
                     </div>
                  </div>

               </div>

            </div>
         </div>
         <div className='d-lg-none  d-md-none d-lg-block' id="mobile-treatment-card-mobile" style={{ backgroundColor: "#EDF8FD", paddingTop: "30px", paddingBottom: "30px" }}>
            <div className='heading-treatment headindtext-second-money-u' style={{ textAlign: "center" }}>Treatments Covered</div>
            <Carousel className='d-lg-none d-xl-block d-md-none d-lg-block mt-4' >
               <Carousel.Item interval={1000}>
                  <div className='card-position-flex pb-3'>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={BB}  alt=""/>
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list'>Skin and Cosmetic Surgery</div>
                           </div>
                        </div>

                     </div>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={CC}  alt=""/>
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list'>IVF and Maternity Care Treatment</div>
                           </div>
                        </div>

                     </div>
                  </div>

               </Carousel.Item>
               <Carousel.Item interval={500}>
               <div className='card-position-flex pb-3'>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={DD}  alt=""/>
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list'>Bariatric Surgery and weight loss treatment</div>
                           </div>
                        </div>

                     </div>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={EE}  alt=""/>
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list'>Hair Restoration</div>
                           </div>
                        </div>

                     </div>
                  </div>

               </Carousel.Item>
               <Carousel.Item>
               <div className='card-position-flex pb-3'>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={FF}  alt="" />
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list'>Ophthalmology</div>
                           </div>
                        </div>

                     </div>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={GG}  alt=""/>
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list'>Orthopedic Surgery</div>
                           </div>
                        </div>

                     </div>
                  </div>
               </Carousel.Item>
            </Carousel>
         </div>
      </>
   )
}

export default CarepalTreatment